<template>
  <template v-if="app">
    <img
      v-if="app.logo"
      class="logo"
      :alt="$t('ui.logo')"
      :src="require(`@/assets/${app.logo}`)"
    >
    <h1 class="title">
      {{ app.title }}
    </h1>
    <p v-html="app.description" />
    <form
      @submit.prevent="getRegId"
    >
      <BaseInput
        id="regcode"
        v-model="code"
        type="text"
        required
        pattern="[A-Za-z0-9]{6}"
        autofocus
        :label="$t('ui.regCode')"
      />
      <BaseButton
        type="submit"
        :disabled="isDisabled"
      >
        {{ $t('ui.send') }}
        <span v-if="remainingTime > 0">
          ({{ remainingTime }})
        </span>
      </BaseButton>
    </form>
  </template>
  <BaseInfobox
    v-if="errorMsg"
    type="error"
    :remove-margins="!app"
    v-html="errorMsg"
  />
</template>

<script>
import BaseInput from '@/components/BaseInput.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseInfobox from '@/components/BaseInfobox.vue'
import RegistrationService from '@/services/RegistrationService.js'
import { setLocale } from '@/util/i18n.js'

export default {
  name: 'RegcodePage',
  components: {
    BaseInput,
    BaseButton,
    BaseInfobox
  },
  data () {
    return {
      app: null,
      errorMsg: '',
      code: '',
      resendDate: undefined,
      remainingTime: undefined,
      intervalId: undefined
    }
  },
  computed: {
    /* Boolean-Attribut muss "null" sein, damit es entfernt wird */
    isDisabled () {
      return this.remainingTime > 0 ? '' : null
    }
  },
  async created () {
    setLocale(this.$route.query.lang)

    const response = await RegistrationService.getAppForCodePage(this.$route.params.appId)
    this.app = response.app
    this.errorMsg = response.errorMsg ? this.$t(response.errorMsg) : ''
  },
  beforeUnmount () {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  methods: {
    async getRegId () {
      const data = {
        code: this.code,
        appId: this.$route.params.appId
      }

      const response = await RegistrationService.getRegId(data)
      if (response.errorMsg) {
        this.errorMsg = this.$t(response.errorMsg)
        this.resendDate = response.resendDate
        this.intervalId = setInterval(this.calculateRemainingTime, 1000)
      } else {
        this.$router.push({ name: 'Registration', params: { regId: response.regId }, query: { lang: this.$route.query.lang } })
      }
    },
    calculateRemainingTime () {
      this.remainingTime = this.resendDate - Math.floor(Date.now() / 1000)
    }
  }
}
</script>
