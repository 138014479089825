<template>
  <button
    class="button"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'BaseButton'
}
</script>

<style scoped>
.button {
  display: block;
  line-height: 2.3;
  width: 16rem;
  max-width: 100%;
  border: 2px solid #777;
  border-radius: 0.6rem;
  padding: 0 0.6rem;
  margin: 1.2rem auto 0 auto;
  font-size: 1rem;
  font-weight: bold;
  font-family: inherit;
  color: inherit;
  cursor: pointer;
}

.button:focus {
  outline: 3px solid dodgerblue;
}

button:focus:not(:focus-visible){
  outline: none;
}

.button[type="submit"] {
  width: 100%;
  margin-top: 2.5rem;
}

.button:disabled {
  cursor: wait;
}
</style>
