<template>
  <p>
    <label
      class="label"
      :for="id"
    >
      {{ label }}
    </label>
    <input
      :id="id"
      class="text-input"
      :type="type"
      :required="required"
      :autocomplete="autocomplete"
      :pattern="pattern"
      :autofocus="autofocus"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
  </p>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['text', 'email', 'password'].includes(value)
      }
    },
    id: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'off'
    },
    pattern: {
      type: String,
      required: false,
      default: null
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['update:modelValue']
}
</script>

<style scoped>
.label {
  font-weight: bold;

}

.text-input {
  line-height: 2.3;
  width: 100%;
  border: 2px solid #777;
  border-radius: 0.6rem;
  margin-top: 0.2rem;
  padding: 0 0.6rem;
}

.text-input:focus {
  outline: 3px solid dodgerblue;
  background-color: #eee;
}

.text-input:focus:not(:focus-visible){
  outline: none;
  background: transparent;
}
</style>
